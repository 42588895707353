// @TODO split to multiple components
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import {
  Footer,
  Layout,
} from '../containers';
import {
  Container,
  GradientBackground,
  Paragraph,
  StyledButton,
} from '../styled';

import { COLORS } from '../utils/constants';

class ThankYouPage extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;
    const { allMarkdownRemark } = data;
    const { edges } = allMarkdownRemark;
    const { contact } = edges.filter(el => el.node.frontmatter.contact)[0].node.frontmatter;
    const { thankyou } = edges.filter(el => el.node.frontmatter.thankyou)[0].node.frontmatter;
    const {
      thankyou_main_text,
      thankyou_sub_text,
      thankyou_button_back,
    } = thankyou;

    return (
      <Layout>
        <GradientBackground style={{
          paddingBottom: '3.75rem', paddingTop: '8.125rem', display: 'flex', alignItems: 'center',
        }}
        >
          <Container>
            <div style={{ textAlign: 'center' }}>
              <Paragraph
                dangerouslySetInnerHTML={{ __html: thankyou_main_text }}
                size="6"
                marginBottom="1rem"
              />
              <Paragraph
                dangerouslySetInnerHTML={{ __html: thankyou_sub_text }}
                size="6"
                marginBottom="3rem"
                style={{ color: COLORS.red }}
              />
              <Link to="/">
                <StyledButton variant="contained" spaceTop={1}>
                  {thankyou_button_back}
                </StyledButton>
              </Link>
            </div>
          </Container>
        </GradientBackground>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default ThankYouPage;

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          thankyou {
              thankyou_main_text
              thankyou_sub_text
              thankyou_button_back
            }
          }
        }
      }
    }
  }
`;
